// LIST
.optica-list {
    display: flex;
    flex-direction: column;
}
.optica-list-header {
}
.optica-list-items {
    @apply min-h-0;
    flex: 1 1 auto;
    overflow-y: auto;
    .optica-list-item {
        &.selected {
            @apply bg-blue-50;
            @apply border-blue-200;
            @apply dark:bg-blue-400;
            @apply dark:border-blue-400;
            @apply dark:bg-opacity-10;
        }
    }
}
.optica-list-footer {    
}

// Sized styling
.optica-list-xs {
    .optica-list-header {
        @apply p-2;
    }
    .optica-list-items {
        .optica-list-item {
            @apply px-2;
            @apply py-1;
            @apply text-xs;
        }
    }
    .optica-list-footer {
        
    }
}
.optica-list-sm {
    .optica-list-header {
        @apply p-2.5;
    }
    .optica-list-items {
        .optica-list-item {
            @apply px-2.5;
            @apply py-2;
            @apply text-sm;
        }
    }
    .optica-list-footer {
        
    }
}
.optica-list-md {
    .optica-list-header {
        @apply py-3;
        @apply px-3.5;
    }
    .optica-list-items {
        .optica-list-item {
            @apply py-3;
            @apply px-3.5;
            @apply text-sm;
        }
    }
    .optica-list-footer {
        
    }
}
.optica-list-lg {
    .optica-list-header {
        @apply p-3.5;
    }
    .optica-list-items {
        .optica-list-item {
            @apply p-3.5;
            @apply text-base;
        }
    }
    .optica-list-footer {
        
    }
}

// NATIVE SELECT
    // background: #fff no-repeat 96% url("data:image/svg+xml;charset=UTF-8,%3Csvg%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20width%3D%2212%22%20height%3D%2212%22%20viewBox%3D%220%200%2012%2012%22%3E%3Ctitle%3Edown-arrow%3C%2Ftitle%3E%3Cg%20fill%3D%22%23000000%22%3E%3Cpath%20d%3D%22M10.293%2C3.293%2C6%2C7.586%2C1.707%2C3.293A1%2C1%2C0%2C0%2C0%2C.293%2C4.707l5%2C5a1%2C1%2C0%2C0%2C0%2C1.414%2C0l5-5a1%2C1%2C0%2C1%2C0-1.414-1.414Z%22%20fill%3D%22%23000000%22%3E%3C%2Fpath%3E%3C%2Fg%3E%3C%2Fsvg%3E");
.optica-native-select {
    position: relative;
    select {
        -moz-appearance: none; 
        -webkit-appearance: none; 
        appearance: none;
        @apply text-base;
    }
    &:after {
        content: '>';
        font: 20px "Consolas", monospace;
        font-weight: bold;
        @apply text-gray-500;
        @apply dark:text-gray-400;
        transform: rotate(90deg);
        right: 10px;
        width: 23px;
        top: 0px;
        @apply border-b;
        @apply border-gray-300;
        @apply dark:border-gray-700;
        position: absolute;
        pointer-events: none;
        display: flex;
        align-items: center;
        justify-content: center;
        // padding-bottom: 7px;
        padding-bottom: 14px;
        height: 10px;
        right: 9px;
        top: 10px;
    }
    &.size-small {
        select {
            font-size: 14px;
        }
        &:after {
            padding-bottom: 14px;
            right: 12px;
            top: 7px;
            height: 10px;
            width: 16px;
        }
    }
}

.notification-error-list {
    overflow: auto;
    >ul {
        list-style-type:disc;
        margin-left: 2rem;
        ul {
            list-style-type:circle;
            margin-left: 2rem;
        }
    }
    .notification-error-list-message {
        @apply dark:text-gray-300;
        @apply text-sm;
    }
    .notification-error-list-items {
        @apply dark:text-gray-300;
        @apply text-xs;
    }
    &.notification-error-list--open {
        height: auto;
        max-height: 300px;
    }
    &.notification-error-list--close {
        height: 0px;
    }
}