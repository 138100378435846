@tailwind base;
@tailwind components;
@tailwind utilities;

@import "components.scss";
@import "animations.scss";
@import "overwrites.scss";
@import "date-picker.scss";

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

::-webkit-scrollbar {
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background: #696969c7;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  background: #9e9e9e;
  border-radius: 20px;
}

:focus-visible {
  outline: none;
}

:root {
  --toastify-icon-color-success: rgb(16, 185, 129) !important;
  --toastify-icon-color-error: rgb(239, 68, 68) !important;
  --toastify-icon-color-warning: rgb(245, 158, 1) !important;
  --toastify-icon-color-info: rgb(96, 165, 250) !important;
}

// .Toastify__toast-theme--colored.Toastify__toast--info {
// }
// .Toastify__toast-theme--colored.Toastify__toast--success {
// }
// .Toastify__toast-theme--colored.Toastify__toast--warning {
// }
// .Toastify__toast-theme--colored.Toastify__toast--error {
//     svg {
//         fill: rgb(16,185,129)!important;
//     }
// }

html,
body {
  /* font-family: "Roboto", "Helvetica", "Arial", sans-serif; */
  /* height: 100%; */
  /* background-image: linear-gradient(-105deg, #009acc, #363795); */
  /* background-image: linear-gradient(-105deg, #e0e0e0, #e1e1ff); */
  @apply text-gray-700;
  @apply dark:text-gray-200;
  letter-spacing: 0.015rem;
}
button:disabled {
  cursor: not-allowed;
}
button {
  @apply leading-tight;
}
a:focus,
option:checked,
button:focus,
*[role="radio"]:focus,
*[role="button"]:focus,
*[role="menuitem"]:focus,
input:focus,
textarea:focus,
.action-outline {
  &:not(.react-select__input) {
    @apply outline-dashed #{!important};
    @apply outline-1 #{!important};
    @apply outline-gray-500 #{!important};
    @apply dark:outline-gray-500 #{!important};
    @apply outline-offset-1 #{!important};
  }
}
a {
  @apply text-blue-500;
  cursor: pointer;
  @apply leading-none;
}
select {
  padding: 2px;
}

input:checked + div,
input:indeterminate + div {
  @apply bg-blue-400;
}
input:checked + div svg,
input:indeterminate + div svg {
  @apply text-gray-200;
  @apply visible;
}
fieldset:disabled [type="checkbox"] + div,
input[type="checkbox"][disabled] + div {
  @apply border-transparent;
}
fieldset:disabled [type="checkbox"] + div,
input:checked[disabled] + div svg {
  @apply text-gray-400;
  @apply dark:text-gray-400;
  @apply border-transparent;
  @apply dark:bg-gray-500;
  @apply bg-gray-300;
  @apply bg-opacity-40;
  @apply dark:bg-opacity-60;
}
fieldset:disabled [type="checkbox"] + div svg {
  @apply text-gray-400;
  @apply dark:text-gray-400;
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

// input:-webkit-autofill,
// input:-webkit-autofill:hover,
// input:-webkit-autofill:focus,
// textarea:-webkit-autofill,
// textarea:-webkit-autofill:hover,
// textarea:-webkit-autofill:focus,
// select:-webkit-autofill,
// select:-webkit-autofill:hover,
// select:-webkit-autofill:focus {
//   border: 1px solid green;
//   -webkit-text-fill-color: green;
//   -webkit-box-shadow: 0 0 0px 1000px #000 inset;
//   transition: background-color 5000s ease-in-out 0s;
// }

// GENERAL
.h-min,
.min-h-min {
  height: min-content;
}
.min-h-max {
  height: max-content;
}
.w-max {
  width: max-content;
}
.break-words {
  word-break: break-word;
}

.add-commas-to-children {
  > :not(:last-child)::after {
    content: ", ";
    margin-right: 6px;
    @apply text-gray-700;
    @apply dark:text-gray-200;
  }
}

// FONT
.text-xxs {
  font-size: 0.6rem;
}

// SHADOWS
.drop-shadow-right {
  &::after {
    position: absolute;
    top: 0;
    right: 0;
    bottom: -1px;
    width: 10px;
    transform: translateX(100%);
    transition: box-shadow 0.3s;
    content: "";
    pointer-events: none;
    box-shadow: inset 5px 0 8px -8px #00000065;
  }
}

// Popper popper-arrow
#popper-arrow,
#popper-arrow::before {
  position: absolute;
  width: 8px;
  height: 8px;
  background: inherit;
}

#popper-arrow {
  visibility: hidden;
}

#popper-arrow::before {
  visibility: visible;
  content: "";
  transform: rotate(45deg);
}
#tooltip[data-popper-placement^="top"] > #popper-arrow {
  bottom: -4px;
}

#tooltip[data-popper-placement^="bottom"] > #popper-arrow {
  top: -4px;
}

#tooltip[data-popper-placement^="left"] > #popper-arrow {
  right: -4px;
}

#tooltip[data-popper-placement^="right"] > #popper-arrow {
  left: -4px;
}

// React Select Overrides
.react-select__control.react-select__control.react-select__control {
  @apply rounded-sm;
}
.react-select__option--is-selected.react-select__option--is-selected {
  @apply bg-gray-500;
  @apply dark:bg-gray-700;
}
.react-select__option--is-focused.react-select__option--is-focused {
  @apply bg-gray-300;
  @apply dark:bg-gray-600;
}
.react-select__option.react-select__option {
  &:active {
    @apply bg-gray-300;
    @apply dark:bg-gray-600;
  }
}

/* TABLE */
table.table-bordered {
  th,
  td {
    @apply border border-gray-700;
    // position: sticky;
    &.cell-fixed {
      z-index: 2 !important;
    }
    &:not(:last-child) {
      // &::before {
      //     position: absolute;
      //     height: 100%;
      //     right: 0;
      //     top: 0;
      //     width: 1px;
      //     @apply bg-gray-200;
      //     @apply dark:bg-gray-700;
      //     transition: background-color 0.3s;
      //     content: '';
      // }
    }
    &.cell-fixed-left-last {
      &::after {
        position: absolute;
        top: 0;
        right: 0;
        bottom: -1px;
        width: 30px;
        transform: translateX(100%);
        transition: box-shadow 0.3s;
        content: "";
        pointer-events: none;
        box-shadow: inset 10px 0 8px -8px #00000065;
      }
    }
  }
}

table.table-sm {
  th,
  td {
    @apply p-1 px-2;
    font-size: 12px !important;
  }
}

table.table-xs {
  th,
  td {
    @apply p-1;
    font-size: 11px !important;
  }
}

table:not(.table-bordered) {
  th {
    // position: sticky;
    &.cell-fixed {
      z-index: 2 !important;
    }
    &:not(:last-child) {
      // &::before {
      //     position: absolute;

      //     right: 0;
      //     top: 0;
      //     width: 1px;
      //     @apply bg-gray-200;
      //     @apply dark:bg-gray-700;
      //     transition: background-color 0.3s;
      //     content: '';

      //     transform: translateY(-50%);
      //     top: 50%;
      //     height: 1.6em;
      // }
    }
  }
  th,
  td {
    // position: sticky;
    &.cell-fixed {
      z-index: 2 !important;
    }
  }
}

// Toastify overrides
.Toastify__close-button--default {
  color: inherit !important;
}

.line-clamp-1 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1;
}

.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.line-clamp-3 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
}
