.document-upload-bounce {
	animation: bounce 10s infinite;
}
@keyframes bounce {
    0%, 20%, 40%, 60%, 80%, 100% {transform: translateY(0);}
    10%, 30%, 50%, 70%, 90% {transform: translateY(-30px);}
}

.heart-rate {
    width: 150px;
    height: 73px;
    position: relative;
    margin: 20px auto;
    transform: scale(.25);
  }
  
  .heart-rate-fade-in {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #1e293b;
    top: 0;
    right: 0;
    animation: heartRateIn 2.5s linear infinite;
  }
  
  .heart-rate-fade-out {
    position: absolute;
    width: 120%;
    height: 100%;
    top: 0;
    left: -120%;
    animation: heartRateOut 2.5s linear infinite;
    background: #1e293b;
    // background: -moz-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    // background: -webkit-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    // background: -o-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    // background: -ms-linear-gradient(left, rgba(255, 255, 255, 1) 0%, rgba(255, 255, 255, 1) 50%, rgba(255, 255, 255, 0) 100%);
    background: linear-gradient(to right, #1e293b 0%, #1e293b 80%, rgba(255, 255, 255, 0) 100%);
  }
  
  @keyframes heartRateIn {
    0% {
      width: 100%;
    }
    50% {
      width: 0;
    }
    100% {
      width: 0;
    }
  }
  
  @keyframes heartRateOut {
    0% {
      left: -120%;
    }
    30% {
      left: -120%;
    }
    100% {
      left: 0;
    }
  }