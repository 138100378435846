.react-datepicker-wrapper {
  width: 100%;
  // max-width: 288px;
}

.react-datepicker {
  display: flex;
  flex-direction: column;
  @apply dark:bg-gray-800;
  @apply bg-white;
  // @apply max-h-80;
}

.react-datepicker__time-list {
  width: 90px;
}

.react-datepicker__triangle {
  display: none;
}

.react-datepicker__month-container {
  @apply p-2;
}

.react-datepicker-popper {
  @apply border-none #{!important};
}
.react-datepicker-popper > div {
  @apply border #{!important};
  @apply rounded #{!important};
  @apply border-gray-300 #{!important};
  @apply dark:border-gray-700 #{!important};
}

.react-datepicker--time-only {
  .react-datepicker__time-container {
    @apply ml-0;
    @apply border-l-0;
    @apply border-l-transparent;
    @apply dark:border-l-transparent;
  }
}

.react-datepicker__time-container {
  @apply ml-2;
  @apply border-l;
  @apply border-l-gray-300;
  @apply dark:border-l-gray-700;
}

.react-datepicker__header--time {
  @apply px-2;
  @apply py-1;
  @apply border-b;
  @apply border-b-gray-300;
  @apply dark:border-b-gray-700;
}

.react-datepicker-time__header {
  @apply text-center;
}

.react-datepicker__time-list {
  @apply max-h-72;
  @apply overflow-y-auto;
}

.react-datepicker__time-list-item {
  @apply px-2;
  @apply h-7;
  @apply flex;
  @apply items-center;
  @apply cursor-default;
  &:not(.react-datepicker__time-list-item--disabled) {
    @apply hover:bg-gray-100;
    @apply hover:bg-opacity-80;
    @apply dark:hover:bg-gray-700;
    @apply dark:hover:bg-opacity-40;
  }
}

.react-datepicker__time-list-item--selected {
  @apply bg-gray-100;
  @apply dark:bg-gray-700;
}

.react-datepicker__time-list-item--disabled {
  @apply bg-gray-100;
  @apply dark:bg-gray-700;
  @apply text-gray-500/60;
  @apply dark:text-gray-400/60;
  @apply cursor-not-allowed;
}

.react-datepicker__day {
  @apply cursor-default;
}

.react-datepicker__input-container input {
  @apply block;
  @apply w-full;
  @apply text-base;
  @apply md:text-sm;
  @apply bg-white;
  @apply dark:bg-gray-900;
  @apply border;
  @apply border-gray-300;
  @apply rounded;
  @apply shadow-sm;
}

.react-datepicker-popper {
  @apply z-40;
  // @apply w-72 ;
  @apply max-h-80;
  @apply text-sm;
  @apply bg-white;
  @apply dark:bg-gray-800;
  @apply shadow-xl;
  // @apply px-3 ;
  // @apply py-2 ;
  @apply border;
  @apply border-gray-300/70;
  @apply dark:border-gray-800/70;
  @apply rounded;
  &.react-datepicker-sm {
    // @apply w-60 ;
    .react-datepicker__day {
      @apply text-xs;
      @apply w-6;
      @apply h-6;
    }
  }
}

input.react-datepicker-time__input {
  @apply text-sm;
  @apply bg-white;
  @apply border;
  @apply dark:bg-gray-800;
  @apply border-gray-200/70;
  @apply dark:border-gray-700/70;
  @apply rounded;
  @apply px-2;
  @apply py-1;
}

input[type="time"]::-webkit-calendar-picker-indicator {
  // filter: invert(48%) sepia(13%) saturate(3207%) hue-rotate(130deg) brightness(95%) contrast(80%);
  @apply filter;
}

.react-datepicker__input-time-container {
  @apply flex;
  @apply pt-2;
  // @apply space-x-2;
  @apply items-center;
  @apply border-t;
  @apply border-gray-200/70;
  @apply dark:border-gray-700/70;
  @apply justify-center;
}

.react-datepicker-time__caption {
  display: none;
}

.react-datepicker-left {
  @apply absolute;
  @apply left-0;
  @apply right-auto;
  @apply top-11;
  @apply transform-none;
}

.react-datepicker-right {
  @apply absolute;
  @apply right-0;
  @apply left-auto;
  @apply top-11;
  @apply transform-none;
}

.react-datepicker__portal {
  @apply absolute;
  @apply z-10;
  @apply w-72;
  @apply text-sm;
  @apply transform-none;
  @apply bg-white;
  @apply shadow;
  @apply px-3;
  @apply py-2;
  @apply top-12;
  @apply right-0;
  @apply border-2;
  @apply border-gray-200;
  @apply rounded;
}

.react-datepicker__month-container {
  @apply flex;
  @apply flex-col;
}

.react-datepicker__month {
  @apply flex 
    flex-col;
}

.react-datepicker__current-month {
  @apply ml-2.5;
  @apply text-lg;
  @apply font-semibold;
  @apply text-gray-800;
}

.react-datepicker__week {
  @apply flex;
  @apply justify-around;
}

.react-datepicker__day-names {
  @apply flex;
  @apply justify-around;
  @apply text-gray-400;
  @apply font-medium;
  @apply text-center;
  @apply text-xs;
}

.react-datepicker__day-name {
  @apply w-8;
  @apply h-8;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply py-1;
  @apply rounded-full;
  @apply text-gray-900;
  @apply dark:text-gray-300;
}

.react-datepicker__navigation {
  @apply absolute;
  @apply top-2;
}

.react-datepicker__navigation--previous {
  @apply right-12;
  @apply w-8;
  @apply h-8;
  @apply rounded;
  @apply transition;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply hover:bg-gray-200;
}

.react-datepicker__navigation--next {
  @apply right-4;
  @apply w-8;
  @apply h-8;
  @apply rounded;
  @apply transition;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply hover:bg-gray-200;
}

.react-datepicker__day {
  @apply mx-0.5;
  @apply mb-1;
  @apply w-8;
  @apply h-8;
  @apply flex;
  @apply items-center;
  @apply justify-center;
  @apply py-1;
  @apply text-sm;
  @apply leading-loose;
  @apply transition;
  @apply text-gray-700;
  @apply dark:text-gray-200;
  @apply rounded;
  &:hover {
    @apply bg-blue-400;
    @apply text-gray-200;
  }
}

.react-datepicker__day--disabled {
  @apply cursor-not-allowed;
  @apply opacity-40;
  @apply hover:bg-transparent;
}

.react-datepicker__day--outside-month {
  @apply text-gray-400;
  @apply dark:text-gray-500;
}

.react-datepicker__day--in-range {
  @apply bg-gray-200;
  @apply dark:bg-blue-300;
  @apply dark:text-gray-100;
}

.react-datepicker__day--in-selecting-range {
  @apply bg-blue-300;
  @apply dark:text-gray-100;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-white;
  @apply border-2;
  @apply border-blue-500;
}

.react-datepicker__day--selecting-range-end {
  @apply bg-white;
  @apply border-2;
  @apply border-blue-500;
}

.react-datepicker__day--selected.react-datepicker__day--selected.react-datepicker__day--selected {
  @apply bg-blue-500;
  @apply text-white;
  @apply dark:text-white;
  @apply hover:text-gray-300;
  // @apply hover:text-gray-700 ;
}

.react-datepicker__day--range-start {
  @apply bg-blue-400;
  @apply dark:bg-blue-400;
  @apply text-white;
  @apply hover:text-gray-700;
  @apply hover:bg-white;
}

.react-datepicker__day--selecting-range-start {
  @apply bg-blue-400;
  @apply dark:bg-blue-400;
  @apply text-white;
  @apply hover:text-gray-700;
  @apply hover:bg-white;
  @apply dark:hover:text-white;
  @apply hover:bg-blue-500;
}
// .react-datepicker__day--selecting-range-start:hover {
//     @apply !bg-blue-400;
//     @apply !dark:bg-blue-400;
//     @apply !text-white;
//     @apply !hover:text-gray-700;
//     @apply !hover:bg-white;
// }

.react-datepicker__day--range-end {
  @apply bg-blue-400;
  @apply dark:bg-blue-400;
  @apply text-white;
  @apply hover:text-gray-700;
  @apply hover:bg-white;
}

.is-range-selector {
  width: auto;
  .react-datepicker__month-container:not(:first-child) {
    .month-selector {
      display: none;
    }
  }
}

.is-week-selector {
  .react-datepicker__week:hover {
    @apply text-white;
    .react-datepicker__day {
      @apply text-white;
      @apply bg-blue-400;
      @apply dark:bg-blue-400;
    }
  }
}

.react-datepicker__day--outside-month {
  // @apply select-none;
  // @apply dark:hover:bg-transparent;
  // @apply cursor-default;
  // @apply pointer-events-none;
  // &+div.react-datepicker__day.react-datepicker__day--outside-month {
  //     display: none;
  // }
}
