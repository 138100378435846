.react-select--is-disabled {
    cursor:not-allowed;
}
.react-select--is-disabled > div {
    cursor:not-allowed;
    @apply text-gray-400;
    @apply dark:text-gray-400;
    @apply border-transparent;
    @apply dark:bg-gray-600;
    @apply bg-gray-300;
    @apply bg-opacity-40;
    @apply dark:bg-opacity-60;
}

// APEX CHARTS
.apexcharts-canvas * {
    font-family: ui-sans-serif, system-ui, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, "Noto Sans", sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol", "Noto Color Emoji"!important;
}
.apexcharts-menu {
    @apply bg-gray-300;
    @apply dark:bg-gray-800;
    @apply border-gray-200 #{!important};
    @apply dark:border-gray-700 #{!important};
    @apply shadow-2xl;
    @apply bg-gray-400;
}
.apexcharts-yaxis-title-text {
    letter-spacing: .05rem;
    @apply fill-gray-700;
    @apply dark:fill-gray-200;
}

.apexcharts-toolbar {
    @apply space-x-1;
    @apply -top-1 #{!important};
    div svg{
        @apply hover:dark:fill-red-600;
    }
}

.apexcharts-menu-item {
    text-align: center;
    @apply hover:dark:bg-gray-700 #{!important};
}

.apexcharts-tooltip.apexcharts-tooltip.apexcharts-tooltip {
    @apply text-gray-700;
    @apply dark:text-gray-200;
    @apply dark:bg-gray-700;

    @apply dark:border-gray-500;    

    .apexcharts-tooltip-title {
        @apply dark:bg-gray-800;
        @apply dark:border-gray-500;
    }
}

.apexcharts-legend-series {
    @apply flex;
    @apply items-center;
    @apply space-x-1;
}

.apexcharts-xaxistooltip.apexcharts-xaxistooltip.apexcharts-xaxistooltip {
    @apply text-gray-700;
    @apply dark:text-gray-200;
    @apply dark:bg-gray-700;

    @apply dark:border-gray-500; 
}

.apexcharts-title-text.apexcharts-title-text.apexcharts-title-text {
    @apply fill-gray-700;
    @apply dark:fill-gray-200;
}
.apexcharts-legend-text {
    @apply truncate;
    @apply inline-block;
    max-width: 275px;
}
.apexcharts-legend-text.apexcharts-legend-text.apexcharts-legend-text {
    @apply text-gray-700 #{!important};
    @apply dark:text-gray-200 #{!important};
}

.apexcharts-text, .apexcharts-yaxis-title-text {
    // @apply fill-gray-700 #{!important};
    // @apply dark:fill-gray-300 #{!important};
}

.apexcharts-xaxis-annotation-label {
    @apply fill-gray-700 #{!important};
    @apply dark:fill-gray-300 #{!important};
}

.apexcharts-yaxis-label, .apexcharts-xaxis-label, .apexcharts-datalabel-value, .apexcharts-datalabel-label {
    @apply text-gray-700 #{!important};
    @apply dark:text-gray-200 #{!important};
    @apply fill-gray-700 #{!important};
    @apply dark:fill-gray-200 #{!important};
}

.apexcharts-datalabel {    
    letter-spacing: .08rem;
    @apply stroke-white #{!important};
    @apply text-white #{!important};
    @apply dark:stroke-gray-200 #{!important};
    @apply stroke-gray-700 #{!important};
    @apply font-normal #{!important};
    @apply text-gray-700 #{!important};
    @apply dark:text-gray-200 #{!important};
}

.apexcharts-marker {
    @apply stroke-white #{!important};
    @apply dark:stroke-gray-800 #{!important};
}

.apexcharts-pie-series * {
    @apply stroke-white #{!important};
    @apply dark:stroke-gray-800 #{!important};
}

.apexcharts-gridline, .apexcharts-xaxis-tick {
    @apply stroke-gray-200 #{!important};
    @apply dark:stroke-gray-600 #{!important};
}

.apexcharts-xaxis line {
    @apply stroke-gray-300 #{!important};
    @apply dark:stroke-gray-600 #{!important};
}

.apexcharts-treemap-rect {
    @apply stroke-white #{!important};
    @apply dark:stroke-gray-800 #{!important};
}

.apexcharts-track {
    path {
        @apply stroke-white #{!important};
        @apply dark:stroke-gray-500 #{!important};
    }        
}